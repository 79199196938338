import styled, { keyframes } from 'styled-components';
import { Scrap } from '../scrap';

export const ImageWrapper = styled.div<{ isDisplayOnScrap?: boolean; isScrapEdit?: boolean }>`
  width: 100%;
  height: 200px;
  background-color: white;
  overflow: hidden;
  position: relative;

  ${(props) => props.isScrapEdit && 'filter: brightness(0.9);'}
`;

export const MainImage = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0px;
  transition: 0.4s;
  width: 100%;
  height: 100%;

  &:first-child {
    z-index: 1;
  }
`;

export const SubImage = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0px;
  transition: 0.4s;
  width: 100%;
  height: 100%;

  &:first-child {
    z-index: 1;
  }
`;

export const skeletonAnimation = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
`;

export const Skeleton = styled.div`
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
  z-index: 2;
  background-color: white;

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(lightgray 178px, transparent 0);
    background-repeat: repeat-y;
    background-size: 100%;
    background-position: 0px 0px;
    animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
  }
`;

export const CardScrap = styled(Scrap)`
  position: absolute;
  z-index: 5;
  right: 4px;
  top: 4px;
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.1));
`;
