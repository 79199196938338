import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import * as Styled from './styled';
import { Timer } from './Timer';

interface props {
  targetDate: Date;
}

/**
 * 프로모션 종료 3일 전에 종료 일자까지 타이머를 보여주는 컴포넌트 입니다.
 */
export const PromotionTimer: React.FC<props> = ({ targetDate }) => {
  // 번역도구
  const { t } = useTranslation(['product']);
  // 종료 일자 - 현재 시간
  const diffTime = targetDate.getTime() - new Date().getTime();
  // 3일에 대한 time
  const threeDayTime = 1000 * 60 * 60 * 24 * 3;
  // 남은 시간 상태 값
  const [remainingTime, setRemainingTime] = useState(diffTime);

  // 종료 일자가 3일 이상 남을 경우 타이머 표시하지 않음.
  if (remainingTime < 1000 || threeDayTime < diffTime) return <div></div>;

  return (
    <Styled.Container>
      <Styled.TimerText>{t('product:timer.promotion')}</Styled.TimerText>
      <Timer setRemainingTime={setRemainingTime} targetDate={targetDate} />
    </Styled.Container>
  );
};
